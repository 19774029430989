import React, { useMemo } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { useAccount, useLoyalty, useRewards } from '../../../redux/hooks';
import styles from './loyaltyPointsBanner.module.css';
import { isEpsilonRewardsOn } from '../../../lib/getFeatureFlags';
import { HIDE_REWARDS_MESSAGE } from './constants';

const rewardsLink = '/account/rewards/';

const LoyaltyPointsBanner = () => {
    const { account } = useAccount();

    const { totalCount: rewardsCount } = useRewards();
    const { loyalty } = useLoyalty();

    const pointsCount = loyalty?.pointsBalance;

    const loyaltyMessage = useMemo(() => {
        if (rewardsCount && pointsCount)
            return (
                <>
                    You’ve got{' '}
                    <Link href={rewardsLink}>
                        <a className={styles.link}>{pointsCount} pts</a>
                    </Link>{' '}
                    &amp;{' '}
                    <Link href={rewardsLink}>
                        <a className={styles.link}>
                            {rewardsCount} reward{rewardsCount > 1 ? 's' : ''}
                        </a>
                    </Link>
                    .
                </>
            );
        if (rewardsCount)
            return (
                <>
                    You’ve got{' '}
                    <Link href={rewardsLink}>
                        <a className={styles.link}>
                            {rewardsCount} reward{rewardsCount > 1 ? 's' : ''}
                        </a>
                    </Link>
                    .
                </>
            );
        if (pointsCount)
            return (
                <>
                    You’ve got{' '}
                    <Link href={rewardsLink}>
                        <a className={styles.link}>{pointsCount} points</a>
                    </Link>
                    .
                </>
            );
        return 'Start earning points to get rewards.';
    }, [pointsCount, rewardsCount]);

    if (HIDE_REWARDS_MESSAGE || !account || !isEpsilonRewardsOn()) return null;

    return (
        <div className={classNames('t-paragraph', styles.wrapper)}>
            Hi {account.firstName}! {loyaltyMessage}
        </div>
    );
};

export default LoyaltyPointsBanner;
